import PersonService from "@/services/PersonService";
import PaymentService from "@/services/PaymentService";
import { validationMixin } from "@zoox-framework/smck-service";

export default {
  name: "ChangePassword",
  mixins: [validationMixin],
  data() {
    return {
      personService: new PersonService(),
      paymentService: new PaymentService(),
      show: false,
      show2: false,
      strongPasswordModal: false,
      recoverData: {
        token: this.$route.query.token,
        password: "",
        confirmPassword: ""
      },
      passwordCheck: 0,
      bufferValue: 100,
      progressBarColor: "#239465",
      passwordStrengthText: this.$t(
        "REGISTRATION.PASSWORD_STRENGTH.WEAK_PASSWORD"
      ),
      showMessage: false,
      isIncorrectPassword: false,
      testPasswordMinCharacter: [
        v =>
          (v && v.length >= 8) ||
          this.$t("REGISTRATION.PASSWORD_VALIDATION.EIGHT_CHARACTERS")
      ],
      testPasswordMinNumber: [
        v =>
          (v && v.match(/[0-9]{1,}/)) ||
          this.$t("REGISTRATION.PASSWORD_VALIDATION.NUMBER")
      ],
      testPasswordUppercase: [
        v =>
          (v && v.match(/[A-Z]{1,}/)) ||
          this.$t("REGISTRATION.PASSWORD_VALIDATION.UPPER_CASE")
      ],
      testPasswordSpecialCharacter: [
        v =>
          (v && v.match(/[!@#$&*]/)) ||
          this.$t("REGISTRATION.PASSWORD_VALIDATION.SPECIAL_CHARACTER")
      ],
      testPasswordLowcase: [
        v =>
          (v && v.match(/[a-z]{1,}/)) ||
          this.$t("REGISTRATION.PASSWORD_VALIDATION.LOWER_CASE")
      ]
    };
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.recoverData.password === this.recoverData.confirmPassword ||
        this.$t("PAY_LOGIN.MATCH_PASSWORD");
    }
  },
  methods: {
    changePassword() {
      if (this.recoverData.password === this.recoverData.confirmPassword) {
        if (this.$refs.changePasswordForm.validate()) {
          this.personService
            .recoverPassword(this.recoverData)
            .then(resp => {
              if (resp) {
                this.showMessage = true;
              }
            })
            .catch(err => {
              if (err.status === 422 && err.bodyText.indexOf("token") > -1) {
                this.$toaster.error(this.$t("RECOVER_PASSWORD.INVALID_TOKEN"));
              }
            });
        }
      } else {
        this.isIncorrectPassword = true;
      }
    },
    checkString(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/\d/.test(this.recoverData.password)) {
        this.checkPasswordStrength();
        return true;
      }
      if (
        /^[A-Za-z]+$/.test(char) ||
        char == "!" ||
        char == "@" ||
        char == "?" ||
        char == "#" ||
        char == "*" ||
        char == " "
      ) {
        this.checkPasswordStrength();
        return true;
      }
      // Match with regex // If not match, don't add to input text
    },
    checkPasswordStrength() {
      if (this.recoverData.password && this.recoverData.password.length >= 8) {
        this.recoverData.password.match(/.{8,}/)
          ? (this.passwordCheck += 2)
          : false;
        this.recoverData.password.match(/[A-Z]{1,}/)
          ? (this.passwordCheck += 2)
          : false;
        this.recoverData.password.match(/[0-9]{1,}/)
          ? (this.passwordCheck += 2)
          : false;
        this.recoverData.password.match(/[!@#$&*]/)
          ? (this.passwordCheck += 2)
          : false;
        this.recoverData.password.match(/[a-z]{1,}/)
          ? (this.passwordCheck += 2)
          : false;
      } else {
        this.passwordCheck = 0;
        this.passwordStrengthText =
          "A senha precisa ter no minimo 8 caracteres";
      }
      if (this.passwordCheck < 20) {
        this.passwordStrengthText = this.$t(
          "REGISTRATION.PASSWORD_STRENGTH.WEAK_PASSWORD"
        );
        this.progressBarColor = "#ff0000";
      } else if (this.passwordCheck >= 10 && this.passwordCheck < 30) {
        this.passwordStrengthText = this.$t(
          "REGISTRATION.PASSWORD_STRENGTH.MEDIUM_PASSWORD"
        );
        this.progressBarColor = "#ffff38";
      } else {
        this.passwordStrengthText = this.$t(
          "REGISTRATION.PASSWORD_STRENGTH.STRONG_PASSWORD"
        );
        this.progressBarColor = "#239465";
      }
    },
    clearPasswordMessage() {
      if (this.recoverData.confirmPassword.length == 0) {
        this.isIncorrectPassword = false;
      }
    },
    goToAuth() {
      const currentToken = this.paymentService.getChargeToken();
      if (currentToken && currentToken != "null") {
        this.$router.push(`/auth?chargeToken=${currentToken}`);
      } else {
        this.$router.push("/auth");
      }
    }
  }
};
